import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ textAlign: 'center' }}>
      <h2>Diese Seite existiert wohl nicht...</h2>
      <p>
        Wenn Du einem Link gefolgt bist, ist dieser Wahrscheinlich veraltet :(
      </p>
      <p>
        Hier geht es zur <Link to="/">Startseite</Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
